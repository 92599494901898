import { ICardPostNewProps } from "@frontiers-vue3/public-pages";
import { IBriefPostResponse } from "~/types/contentful/responses/";
import { mapAssetToFrontiersImageByMedia } from ".";
import { IContentfulImagesQueryByMedia } from "~/types/contentful/assets";
import { mapToPostUrl } from "../models";

const mapToCardPostNew = (
  cms: IBriefPostResponse,
  imgQueries: IContentfulImagesQueryByMedia[],
  lazyLoadingAttr: boolean
): ICardPostNewProps => {
  return {
    pretitle: cms.categoriesCollection.items[0].name,
    title: cms.title,
    description: cms.excerpt,
    date: cms.publishedOn,
    frontiersImage: mapAssetToFrontiersImageByMedia(
      cms.featuredMedia,
      imgQueries,
      lazyLoadingAttr
    ),
    url: mapToPostUrl(cms),
    target: "_self"
  };
};

export { mapToCardPostNew };
