<script lang="ts" setup>
import {
  CustomLink,
  ICardPostNewProps,
  IFrontiersImageProps
} from "@frontiers-vue3/public-pages";
import {
  mapAssetToFrontiersImageByMedia,
  mapToCardPostNew
} from "~/mappers/components";
import {
  IBriefPostResponse,
  ILandingPageResponse
} from "~/types/contentful/responses";
import { useContentfulQuery, useCacheService } from "~/services";
import { ISeo } from "~/types/contentful/models";
import { homePageLDJson } from "~/json/google-structure-data";
import {
  CardNewsLatestImageQueries,
  CardNewsMainImageQueries,
  CardNewsSecondaryImageQueries,
  MainHeaderImageQueries
} from "~/const/ImageQueriesByMedia";

const { usePageCache, shortTTL } = useCacheService();
usePageCache(shortTTL);

const contenfulService = useContentfulQuery();
const config = useRuntimeConfig();

const landingPage: ILandingPageResponse = useMain().landingPage;

const featuredBriefPostListResponse = await contenfulService.getBriefPostList({
  limit: 5,
  categories: ["Featured news"]
});

const latestBriefPostListResponse = await contenfulService.getBriefPostList({
  limit: 12
});

const mainHeaderImage = computed((): IFrontiersImageProps => {
  const image = mapAssetToFrontiersImageByMedia(
    landingPage.mainHeaderImage,
    MainHeaderImageQueries,
    false
  );
  return image;
});

const mainHeaderImages = computed((): IFrontiersImageProps[] => {
  const images = landingPage.mainHeaderImagesCollection?.items.map((i) =>
    mapAssetToFrontiersImageByMedia(i, MainHeaderImageQueries, false)
  );
  return images || [mainHeaderImage.value];
});

const seoData = computed((): ISeo => {
  return landingPage.seo;
});

const featuredNews = computed((): Array<ICardPostNewProps> => {
  const cardsProps = featuredBriefPostListResponse.items.map(
    (card: IBriefPostResponse, i: number) => {
      let mappedCard;
      if (i === 0) {
        mappedCard = mapToCardPostNew(card, CardNewsMainImageQueries, false);
      } else {
        mappedCard = mapToCardPostNew(
          card,
          CardNewsSecondaryImageQueries,
          true
        );
      }
      return mappedCard;
    }
  );
  return cardsProps;
});

const latestNews = computed((): Array<ICardPostNewProps> => {
  const cardsProps = latestBriefPostListResponse.items.map(
    (card: IBriefPostResponse) =>
      mapToCardPostNew(card, CardNewsLatestImageQueries, true)
  );
  return cardsProps;
});

const viewAllLink = {
  text: "View all news",
  url: "/news/all",
  target: "_self"
};

const seo = useSeo(seoData.value);
seo.setPageMetaInfo();

useHead({
  link: [
    {
      rel: "alternate",
      type: "application/rss+xml",
      title: "RSS Feed for Frontiers Science News",
      href: `${config.public.baseUrl}/news/rss`
    }
  ],
  script: [
    {
      type: "application/ld+json",
      children: JSON.stringify(homePageLDJson(config))
    }
  ]
});

const randomHeroBanner = ref<IFrontiersImageProps | undefined>();
const randomNumber = Math.floor(Math.random() * mainHeaderImages.value.length);
randomHeroBanner.value = mainHeaderImages.value[randomNumber];
</script>

<template>
  <HomeMainHeader
    class="Parallax"
    title="Science news"
    :image="randomHeroBanner" />
  <div class="Parallax__wrapper">
    <div class="Layout__body Layout__body--mainAside">
      <main class="Layout__main">
        <NewsSearchBar />
        <section class="Layout__section Layout__section--sm">
          <h2>Featured news</h2>
          <CardGridHome type="Primary" :cards="featuredNews" />
        </section>
        <section class="Layout__section Layout__section--xl">
          <h2>Latest news</h2>
          <CardGridHome type="Secondary" :cards="latestNews" />
        </section>
        <CustomLink type="FlatButton" :link="viewAllLink" color="Blue80" />
      </main>
      <LayoutAside />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.Layout {
  &__body {
    margin-top: var(--spacing-vertical-sm);
  }
  &__main {
    .Link__wrapper {
      margin: var(--spacing-vertical-sm) auto 0 auto;
      text-align: center;
      display: block;

      @media #{$break-md} {
        margin-bottom: var(--spacing-vertical-xl);
      }
    }
  }
}
h2 {
  &:first-child {
    margin-top: 0;
  }
}

.Parallax__wrapper {
  background: #f7f7f7;
  z-index: 1;
  position: relative;
  padding-top: 1px;
}
</style>
